import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'en',
    label: 'English',
    icon: '/static/icons/ic_flag_en.svg'
  }
  // },
  // {
  //   value: 'pt-BR',
  //   label: 'Portuguese',
  //   icon: '/static/icons/ic_flag_br.svg'
  // },
  ,
  {
    value: 'hi',
    label: 'Hindi',
    icon: '/static/icons/ic_flag_in.svg'
  }
];

// https://flagicons.lipis.dev/
// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage);

  const handleChangeLanguage = (newlang) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS
  };
}
