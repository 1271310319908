import { useState, useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Outlet, NavLink } from 'react-router-dom';
// material
import { Box, Container, Typography } from '@material-ui/core';
// components
// import Logo from '../../components/Logo';
//
import MainNavbar from './MainNavbar';
// ----------------------------------------------------------------------

export default function MainLayout() {
  const [isMobileW, setIsMobileW] = useState(false)
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  }, []);

  const handleResize = () => {
    if (window.innerWidth <= 830) {
      setIsMobileW(true)
    } else {
      setIsMobileW(false)
    }
  }
  return (
    <>
      <MainNavbar />
      <div>
        <Outlet />
      </div>
      <Box
        sx={{
          py: 5,
          textAlign: isMobile || isMobileW ? 'center' : 'left',
          position: 'relative',
          bgcolor: 'background.default'
        }}
      >
        <Container maxWidth="lg">
          <ScrollLink to="move_top" spy smooth>
            <img style={{ width: isMobile || isMobileW ? '5%' : '3%' }} src='/static/icons/Logo.svg' alt="React Logo" />
          </ScrollLink>

          <Typography variant="caption" component="p" display="flex" flexDirection="column">
            <br /> PromptMe &nbsp;
            <Typography variant="caption" component="p" display="flex" flexDirection="column">
              Capture Voice Memories and inspire Deep Conversations with your loved ones with PromptMe
            </Typography>
            <NavLink to="/privacy-policy">Privacy Policy</NavLink>
            <NavLink to="/terms-and-conditions">Terms of Use</NavLink>
          </Typography>
        </Container>
      </Box>
    </>
  );
}
