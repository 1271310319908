// ----------------------------------------------------------------------
export const getRecipients = async (userId) => {
  const recipients = await fetch(`https://${process.env.REACT_APP_API_ENDPOINT}/recipients/created_by?id=${userId}`, { credentials: "include" })
    .then(response => response.json())
    .then((data) => data
    )
    .catch((error) => {
      console.log(error)
      return null
    })
  const { data } = recipients
  const columns = data
  const temp = data?.map((r) => r.id)
  const columnIds = temp?.reduce((a, v, i) => ({ ...a, [`column${i}`]: v }), {})
  return { columnIds, columns }
};

export const getUserPrompts = async (userId) => {
  const prompts = await fetch(`https://${process.env.REACT_APP_API_ENDPOINT}/prompts?created_by=${userId}`, { credentials: "include" })
    .then(response => response.json())
    .then((data) => data
    )
    .catch((error) => {
      console.log(error)
      return null
    })
  const { data } = prompts
  const cardList = []
  data?.map((d) => (
    cardList.push({
      attachments: [],
      comments: [],
      completed: d?.is_sent,
      description: 'Duis condimentum lacus finibus felis pellentesque, ac auctor nibh fermentum. Duis sed dui ante. Phasellus id eros tincidunt, dictum lorem vitae, pellentesque sem. Aenean eu enim sit amet mauris rhoncus mollis. Sed enim turpis, porta a felis et, luctus faucibus nisi. Phasellus et metus fermentum, ultrices arcu aliquam, facilisis justo. Cras nunc nunc, elementum sed euismod ut, maximus eget nibh. Phasellus condimentum lorem neque. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Fusce sagittis pharetra eleifend. Suspendisse potenti.',
      due: d?.date,
      id: d?.id,
      name: d?.prompt || "",
    })
  ))
  // const cardIds = temp.reduce((a, v, i) => ({ ...a, [`card${i}`]: v }), {})
  return { cardList, data }
}

const getRecipientPrompts = async (recipientId = "0") => {
  const prompts = await fetch(`https://${process.env.REACT_APP_API_ENDPOINT}/prompts?recipient_id=${recipientId}`, { credentials: "include" })
    .then(response => response.json())
    .then((data) => data
    )
    .catch((error) => {
      console.log(error)
      return null
    })
  const { data } = prompts
  sortByDate(data);
  const unsent = currentUnsentPrompt(data)
  const sent = currentSentPrompt(data)
  if (!unsent[0]) {
    if (!sent[0]) {
      return null
    }
    const arr = [sent[0]]
    const ids = arr.map((d) => d.id
    )
    return ids
  }
  if (!sent[0]) {
    if (!unsent[0]) {
      return null
    }
    const arr = [unsent[0]]
    const ids = arr.map((d) => d.id
    )
    return ids
  }
  if (sent[0] && unsent[0]) {
    const arr = [unsent[0], sent[sent.length - 1]]
    const ids = arr.map((d) => d.id
    )
    return ids
  }
  return null
}

const sortByDate = data => {
  const sorter = (a, b) => new Date(a?.date).getTime() - new Date(b?.date).getTime();
  data.sort(sorter);
};

const currentUnsentPrompt = data => {
  const result = data.filter(d => d?.is_sent === false);
  return result
};

const currentSentPrompt = data => {
  const result = data.filter(d => d?.is_sent === true);
  return result
};

export const getBoardNew = async (userId) => {
  const { columnIds, columns } = await getRecipients(userId)
  const { cardList } = await getUserPrompts(userId)
  const columnList = []
  const mappedArray = await Promise.all(
    columns.map(c => getRecipientPrompts(c?.id))
  );
  columns?.map((s, i) =>
    columnList?.push({
      id: s?.id || "",
      name: s?.nickname || "",
      cardIds: mappedArray[i] || []
    })
  )
  const board = {
    cards: cardList,
    columns: columnList,
    columnOrder: [columnIds?.column0, columnIds?.column1, columnIds?.column2, columnIds?.column3, columnIds?.column4, columnIds?.column5, columnIds?.column6, columnIds?.column7, columnIds?.column8, columnIds?.column9, columnIds?.column10, columnIds?.column11, columnIds?.column12, columnIds?.column13, columnIds?.column14, columnIds?.column15,]
  };
  return board
}

export const updateRecipientName = async (id, newName) => {
  const recipientBody = { id, nickname: newName }
  const updateRecipientName = await fetch(`https://${process.env.REACT_APP_API_ENDPOINT}/recipients/${id}`, {
    method: 'PUT',
    body: JSON.stringify(recipientBody),
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: "include"
  })
    .then(response => {
      if (response.status !== 200) {
        return false
      }
      return true
    })
    .catch((error) => {
      console.log(error)
      return false
    })
  return updateRecipientName
}

export const getCollaborators = async (id) => {
  const collaborators = await fetch(`https://${process.env.REACT_APP_API_ENDPOINT}/groups/invite/collaborators/${id}`, {
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: "include"
  }).then(response => response.json())
    .then((data) => data
    )
    .catch((error) => {
      console.log(error)
      return null
    })
  return collaborators
}

export const getGroupDetails = async (id) => {
  const groupDetails = await fetch(`https://${process.env.REACT_APP_API_ENDPOINT}/groups/${id}`, {
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: "include"
  }).then(response => response.json())
    .then((data) => data
    )
    .catch((error) => {
      console.log(error)
      return null
    })
  return groupDetails
}
