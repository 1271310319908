import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import RecordLayout from '../layouts/recordLayout';
import DashboardLayout from '../layouts/dashboard';
import DashboardLayoutUnauth from '../layouts/dashboard/DashboardLayoutUnauth';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'reset-password', element: <ResetPassword /> },
        // { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Kanban /> },
        { path: 'onboarding', element: <Onboarding /> },
        { path: 'testpage', element: <TestPage /> },
        { path: 'mystories', element: <MyStories /> },
        { path: 'timeline', element: <Timeline /> },
        {
          path: 'user',
          children: [
            { path: '/', element: <Navigate to="/dashboard/user/profile" replace /> },
            { path: 'profile', element: <UserProfile /> },
            { path: 'new', element: <UserCreate /> },
            { path: '/:name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ]
        },
        { path: 'recipient/:recipientId', element: <Recipient /> },
        { path: 'kanban', element: <Kanban /> }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: 'unsubscribe/:jwt', element: <Unsubscribe /> },
        { path: 'registered-texts', element: <RegisteredText /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: (
        <MainLayout />
      ),
      children: [
        { path: '/', element: <About /> },
      ]
    },
    {
      path: '/',
      element: (
        <RecordLayout />),
      children: [
        { path: 'privacy-policy', element: <PrivacyPolicies /> },
        { path: 'terms-and-conditions', element: <TermsAndConditions /> },
        { path: '/record/:jwt', element: <Record /> },
      ]
    },
    {
      path: '/',
      element: (
        <DashboardLayoutUnauth />),
      children: [
        { path: '/responses/:recipientId', element: <Responses /> },
        {
          path: '/responses/register/:recipientId',
          element: (
            <RegisterUnauth />
          )
        },
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const RegisterUnauth = Loadable(lazy(() => import('../pages/RegisterUnauth')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const MyStories = Loadable(lazy(() => import('../pages/dashboard/MyStories')));
const Timeline = Loadable(lazy(() => import('../pages/dashboard/Timeline')));
const Recipient = Loadable(lazy(() => import('../pages/dashboard/Recipient')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
const Onboarding = Loadable(lazy(() => import('../pages/dashboard/Onboarding')));
const TestPage = Loadable(lazy(() => import('../pages/dashboard/TestPage')));
// Main
const About = Loadable(lazy(() => import('../pages/About')));
const PrivacyPolicies = Loadable(lazy(() => import('../pages/PrivacyPolicies')));
const TermsAndConditions = Loadable(lazy(() => import('../pages/TermsAndConditions')));
const Record = Loadable(lazy(() => import('../pages/Record')));
const Responses = Loadable(lazy(() => import('../pages/Responses')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Unsubscribe = Loadable(lazy(() => import('../pages/Unsubscribe')));
const RegisteredText = Loadable(lazy(() => import('../pages/RegisteredText')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
