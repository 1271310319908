import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Drawer } from '@material-ui/core';
// components
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
import { PATH_DASHBOARD, PATH_PAGE } from '../../routes/paths';
// hooks
import useLocales from '../../hooks/useLocales';
// redux
import { useSelector, useDispatch } from '../../redux/store';
// components
import { getMyStories, getUsers } from '../../redux/slices/user';
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 200;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const { mystories } = useSelector((state) => state.user);
  const { users, groups } = useSelector((state) => state.user);
  const [myStories, setMyStories] = useState([]);
  const { user } = useAuth();

  let collection;
  if (users?.length && !groups?.length) {
    collection = [...users]
  }
  else if (!users?.length && groups?.length) {
    collection = [...groups]
  }
  else if (groups?.length && users?.length) {
    collection = [...users, ...groups]
  }

  collection?.sort((a, b) =>
    new Date(a?.date) - new Date(b?.date)
  );

  useEffect(() => {
    dispatch(getUsers(user?.id));
    dispatch(getMyStories(user?.email));
  }, [dispatch]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchResponses()
  }, [mystories])// eslint-disable-line react-hooks/exhaustive-deps

  async function fetchResponses() {
    try {
      const responses = mystories.filter(r => r?.first_name && r?.prompt);
      if (responses !== null) {
        setMyStories(responses)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const sidebarConfig = [
    {
      subheader: translate('dashboardSidebar.promptme'),
      items: [
        {
          title: translate('dashboardSidebar.storytellers'),
          path: PATH_DASHBOARD.root,
          count: collection?.length
        },
        { title: translate('dashboardSidebar.myStories'), path: PATH_DASHBOARD.mystories, count: myStories?.length },
        { title: translate('dashboardSidebar.timeline'), path: PATH_DASHBOARD.timeline, count: 0 }
      ],
    },
    {
      subheader: translate('dashboardSidebar.about'),
      items: [
        {
          title: translate('dashboardSidebar.privacypolicies'),
          path: PATH_PAGE.privacyPolicies,
        },
        { title: translate('dashboardSidebar.termsofuse'), path: PATH_PAGE.termsAndCondition },
      ]
    }
  ]

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{ height: '100%', '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' } }}
    >
      <Box sx={{ px: 5.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
          <img style={{ width: '20%', height: '20%' }} src='/static/icons/Logo.svg' alt="React Logo" />
        </Box>
      </Box>
      <NavSection navConfig={sidebarConfig} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
