import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
// components

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0)
  }
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const [isMobileW, setIsMobileW] = useState(false)
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  }, []);

  const handleResize = () => {
    if (window.innerWidth <= 720) {
      setIsMobileW(true)
    } else {
      setIsMobileW(false)
    }
  }

  return (
    <>
      <HeaderStyle>
        <img style={isMobile || isMobileW ? { width: '10%', height: '10%' } : { width: '50px', height: '50px' }} src='/static/icons/Logo.svg' alt="React Logo" />
      </HeaderStyle>
      <Outlet />
    </>
  );
}
