// ----------------------------------------------------------------------

export const getNotifications = async (userId) => {
  const notifications = await fetch(`https://${process.env.REACT_APP_API_ENDPOINT}/notifications?user_id=${userId}`, { credentials: "include" })
    .then(response => response.json())
    .then((data) => data
    )
    .catch((error) => {
      console.log(error)
      return null
    })
  return notifications
};

export const setNotificationsFalse = async (notificationIdsArray) => {
  const setNotificationsFalse = await fetch(`https://${process.env.REACT_APP_API_ENDPOINT}/notifications`, {
    method: 'PUT',
    body: JSON.stringify({ ids: notificationIdsArray }),
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: "include"
  })
    .then(response => {
      if (response.status !== 200) {
        return false
      }
      return true
    })
    .catch((error) => {
      console.log(error)
      return false
    })
  return setNotificationsFalse
};

export const createNotification = async (userId, promptId, recipientId, notificationType) => {
  const notificationbody = {
    notification_type: notificationType,
    is_new: true,
    user_id: userId,
    prompt_id: promptId,
    recipient_id: recipientId
  }
  const createNotification = await fetch(`https://${process.env.REACT_APP_API_ENDPOINT}/notifications`, {
    method: 'POST',
    body: JSON.stringify(notificationbody),
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: "include"
  })
    .then(response => {
      if (response.status !== 201) {
        return false
      }
      return true
    })
    .catch((error) => {
      console.log(error)
      return false
    })
  return createNotification
};
