import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

// utils
// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { data } = action.payload;
    return {
      ...state,
      data
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state) => (
    {
      ...state,
      user: null
    }
  )
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const loggingIn = await fetch(`https://${process.env.REACT_APP_API_ENDPOINT}/api/user`,
          { credentials: "include" })
        const { data } = await loggingIn.json()
        if (loggingIn.status === 200) {
          return dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: data
            }
          });
        }
        return dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      } catch (err) {
        console.error(err);
        return dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email) => {
    const body = { email }
    const loggingIn = await fetch(`https://${process.env.REACT_APP_API_ENDPOINT}/login`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .then(response => {
        if (response.status !== 200) {
          return false
        }
        return true
      })
      .catch((error) => {
        dispatch({ type: 'LOGOUT' });
        console.log(error)
        return false
      })
    return loggingIn
  };

  const register = async (registerBody) => {
    const registering = await fetch(`https://${process.env.REACT_APP_API_ENDPOINT}/register`,
      {
        method: "POST", body: JSON.stringify(registerBody),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        if (!response.status === 200) {
          return response.status
        }
        return response.status
      })
      .catch((error) => {
        dispatch({ type: 'LOGOUT' });
        console.log(error)
        return null
      })
    return registering
  };

  const logout = async () => {
    try {
      await fetch(`https://${process.env.REACT_APP_API_ENDPOINT}/api/logout`,
        { credentials: "include" })
    } catch (e) {
      console.log(e)
    }
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
