// ----------------------------------------------------------------------
export const getRecipientPrompts = async (userId) => {
  const promptsData = await fetch(`https://${process.env.REACT_APP_API_ENDPOINT}/prompts/recipientPrompts/${userId}`, { credentials: "include" })
    .then(response => response.json())
    .then((data) => data
    )
    .catch((error) => {
      console.log(error)
      return null
    })
  return promptsData
}

export const getRecipientGroups = async (userId) => {
  const groupsData = await fetch(`https://${process.env.REACT_APP_API_ENDPOINT}/prompts/groupPrompts/${userId}`, { credentials: "include" })
    .then(response => response.json())
    .then((data) => data
    )
    .catch((error) => {
      console.log(error)
      return null
    })
  return groupsData
}