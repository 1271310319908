// import faker from 'faker';
import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { set, sub, formatDistanceToNow } from 'date-fns';
import { formatDistanceToNow } from 'date-fns';
import { Icon } from '@iconify/react';
import bellFill from '@iconify/icons-eva/bell-fill';
import clockFill from '@iconify/icons-eva/clock-fill';
import doneAllFill from '@iconify/icons-eva/done-all-fill';
// material
import { alpha } from '@material-ui/core/styles';
import {
  Box,
  List,
  Badge,
  Avatar,
  Tooltip,
  Divider,
  ListItem,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar
} from '@material-ui/core';
// components
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';
// api
import { getNotifications, setNotificationsFalse } from '../../api/notifications';
// hooks
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [totalUnRead, setTotalUnRead] = useState(0);
  const { user } = useAuth();

  useEffect(() => {
    fetchUserNotifications()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function renderContent(notification) {
    if (notification?.notification_type === 'uploaded') {
      return {
        avatar: <img alt={notification?.title} src={notification?.recipient_profile_pic || "/static/icons/ic_notification_package.svg"} />,
        title: (
          <Typography variant="subtitle2">
            Response uploaded
            <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
              &nbsp; {noCase(`${notification?.recipient_nickname || "a storyteller"} answered your prompt.`)}
            </Typography>
          </Typography>
        ),
        name: "uploaded",
        task: notification?.recipient_id
      };
    }
    if (notification?.notification_type === 'skipped') {
      return {
        avatar: <img alt={notification?.title} src={notification?.recipient_profile_pic || "/static/icons/ic_notification_shipping.svg"} />,
        title: (
          <Typography variant="subtitle2">
            Prompt skipped
            <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
              &nbsp; {noCase(`${notification?.recipient_nickname || "a storyteller"} skipped your prompt.`)}
            </Typography>
          </Typography>
        ),
        name: "skipped",
        task: notification?.recipient_id
      };
    }
    if (notification?.notification_type === 'new') {
      return {
        avatar: <img alt={notification?.title} src={notification?.recipient_profile_pic || "/static/icons/ic_notification_mail.svg"} />,
        title: (
          <Typography variant="subtitle2">
            A new prompt
            <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
              &nbsp; {noCase(`${user?.nickName || " "} you have a new prompt from ${notification?.user_firstname || "a storyteller"}.`)}
            </Typography>
          </Typography>
        ),
        name: "new",
        task: notification?.prompt_id
      };
    }
    if (notification?.notification_type === 'listened') {
      return {
        avatar: <img alt={notification?.title} src={notification?.recipient_profile_pic || "/static/icons/ic_notification_chat.svg"} />,
        title: (
          <Typography variant="subtitle2">
            Response listened
            <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
              &nbsp; {noCase(`${notification?.recipient_nickname || "a storyteller"} listened to your prompt.`)}
            </Typography>
          </Typography>
        ),
        name: "listened",
        task: notification?.recipient_id
      };
    }
    return {
      avatar: <img alt={notification?.title} src={notification?.avatar} />,
      title: (
        <Typography variant="subtitle2">
          notification
          <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
            &nbsp; {noCase(`a new notification`)}
          </Typography>
        </Typography>
      ),
      name: "wrong",
      task: "nothing"
    };
  }

  NotificationItem.propTypes = {
    notification: PropTypes.object,
  };

  function NotificationItem({ notification }) {
    const navigate = useNavigate();
    const { avatar, title, name, task } = renderContent(notification);
    const sendLocation = () => {
      if (name === "wrong") {
        handleMarkAllAsRead()
        return null
      }
      if (name === "listened") {
        handleMarkAllAsRead()
        return null
      }
      if (name === "new") {
        handleMarkAllAsRead()
        return navigate(`/record/${task}`)
      }
      if (name === "skipped") {
        handleMarkAllAsRead()
        return null
      }
      if (name === "uploaded") {
        handleMarkAllAsRead()
        return navigate(`/dashboard/recipient/${task}?sent=true`)
      }
      return null
    }

    return (
      <ListItem
        button
        onClick={sendLocation}
        disableGutters
        sx={{
          py: 1.5,
          px: 2.5,
          mt: '1px',
          ...(notification.is_new && {
            bgcolor: 'action.selected'
          })
        }}
      >
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={title}
          secondary={
            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: 'flex',
                alignItems: 'center',
                color: 'text.disabled'
              }}
            >
              <Box component={Icon} icon={clockFill} sx={{ mr: 0.5, width: 16, height: 16 }} />
              {formatDistanceToNow(new Date(notification?.created_at))}
            </Typography>
          }
        />
      </ListItem>
    );
  }

  async function fetchUserNotifications() {
    try {
      const notis = await getNotifications(user?.id)
      setNotifications(notis?.data)
      setTotalUnRead(notis?.data?.filter((item) => item.is_new === true).length)
      // setUserPrompts(popResponses)
    } catch (e) {
      console.log(e)
    }
  }

  const handleOpen = () => {
    setOpen(true);
    handleMarkAllAsRead()
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAllAsRead = () => {
    const ids = notifications?.map((notification) =>
      notification?.id
    )
    const unreadFalse = setNotificationsFalse(ids)
    if (unreadFalse) {
      setNotifications(
        notifications?.map((notification) => ({
          ...notification,
          is_new: false
        }))
      );
      setTotalUnRead(0)
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        color={open ? 'primary' : 'default'}
        sx={{
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 360 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <MIconButton color="primary" onClick={handleMarkAllAsRead}>
                <Icon icon={doneAllFill} width={20} height={20} />
              </MIconButton>
            </Tooltip>
          )}
        </Box>

        <Divider />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                New
              </ListSubheader>
            }
          >
            {notifications?.slice(0, 2).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications?.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List>
        </Scrollbar>

        <Divider />

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple component={RouterLink} to="#">
            View All
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
}
