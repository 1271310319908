import { Helmet } from 'react-helmet-async';
// import { googleAnalyticsConfig } from '../config';

// ----------------------------------------------------------------------

// let GA_MEASUREMENT_ID = 'G-Z7ESLHS6V2'; // dev
let GA_MEASUREMENT_ID = 'G-8G32CNMLV3'; // prod
export default function GoogleAnalytics() {
  const domain = window.location.host;
  if (domain === 'qa.promptme.io') {
    GA_MEASUREMENT_ID = 'G-Z7ESLHS6V2'
  }
  return (
    <Helmet>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`} />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          gtag('js', new Date());
          gtag('config', '${GA_MEASUREMENT_ID}');
        `}
      </script>
    </Helmet>
  );
}
