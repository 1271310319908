// ----------------------------------------------------------------------

export const getMystoriesData = async (userEmail) => {
  const mystoriesData = await fetch(`https://${process.env.REACT_APP_API_ENDPOINT}/myStories?email=${userEmail}`, {
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: "include"
  })
    .then(response => response.json())
    .then(data =>
      data
    );
  return mystoriesData
};

// testing

